/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-08-27",
    versionChannel: "nightly",
    buildDate: "2024-08-27T00:05:43.915Z",
    commitHash: "713224c4652e97c1f109266af5606be814219996",
};
