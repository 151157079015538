import { jsx as _jsx } from "tsx-dom/jsx-runtime";
import { StaticStyles } from "./static-styles";
import { DynamicStyles } from "./dynamic-styles";
import { MainContainer } from "./container";
import { SplashScreen } from "./splash-screen";
import { SaveManager } from "./save-manager";
import { VolumeControls } from "./volume-controls";
import { UnsupportedVideo } from "./unsupported-video";
import { HardwareAcceleration } from "./hardware-acceleration";
import { ClipboardPermission } from "./clipboard-permission";
import { ContextMenuOverlay } from "./context-menu-overlay";
/*
 *
 * The shadow template which is used to fill the actual Ruffle player element
 * on the page.
 *
 */
export const ruffleShadowTemplate = document.createElement("template");
ruffleShadowTemplate.content.appendChild(_jsx(StaticStyles, {}));
ruffleShadowTemplate.content.appendChild(_jsx(DynamicStyles, {}));
ruffleShadowTemplate.content.appendChild(_jsx(MainContainer, {}));
ruffleShadowTemplate.content.appendChild(_jsx(SplashScreen, {}));
ruffleShadowTemplate.content.appendChild(_jsx(SaveManager, {}));
ruffleShadowTemplate.content.appendChild(_jsx(VolumeControls, {}));
ruffleShadowTemplate.content.appendChild(_jsx(UnsupportedVideo, {}));
ruffleShadowTemplate.content.appendChild(_jsx(HardwareAcceleration, {}));
ruffleShadowTemplate.content.appendChild(_jsx(ClipboardPermission, {}));
ruffleShadowTemplate.content.appendChild(_jsx(ContextMenuOverlay, {}));
