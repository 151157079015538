export class LoadSwfError extends Error {
    constructor(swfUrl) {
        super(`Failed to fetch ${swfUrl}`);
        this.swfUrl = swfUrl;
        this.swfUrl = swfUrl;
    }
}
export class InvalidSwfError extends Error {
    constructor(swfUrl) {
        super(`Not a valid swf: ${swfUrl}`);
    }
}
export class LoadRuffleWasmError extends Error {
    constructor(cause) {
        super("Failed to load Ruffle WASM");
        this.cause = cause;
    }
}
export class InvalidOptionsError extends Error {
    constructor(message) {
        super(`Invalid options: ${message}`);
    }
}
