var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _RufflePlayerElement_inner;
import { InnerPlayer, ReadyState } from "./inner";
/**
 * The ruffle player element that should be inserted onto the page.
 *
 * This element will represent the rendered and intractable flash movie.
 */
export class RufflePlayerElement extends HTMLElement {
    get onFSCommand() {
        return __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").onFSCommand;
    }
    set onFSCommand(value) {
        __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").onFSCommand = value;
    }
    get readyState() {
        return __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f")._readyState;
    }
    get metadata() {
        return __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").metadata;
    }
    constructor() {
        super();
        _RufflePlayerElement_inner.set(this, void 0);
        __classPrivateFieldSet(this, _RufflePlayerElement_inner, new InnerPlayer(this, () => this.debugPlayerInfo(), (name) => {
            try {
                Object.defineProperty(this, name, {
                    value: (...args) => {
                        return __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").callExternalInterface(name, args);
                    },
                    configurable: true,
                });
            }
            catch (error) {
                console.warn(`Error setting ExternalInterface legacy callback for ${name}`, error);
            }
        }), "f");
    }
    get loadedConfig() {
        return __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").loadedConfig ?? null;
    }
    connectedCallback() {
        __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").updateStyles();
    }
    static get observedAttributes() {
        return ["width", "height"];
    }
    attributeChangedCallback(name, _oldValue, _newValue) {
        if (name === "width" || name === "height") {
            __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").updateStyles();
        }
    }
    disconnectedCallback() {
        __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").destroy();
    }
    async reload() {
        await __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").reload();
    }
    async load(options, isPolyfillElement = false) {
        await __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").load(options, isPolyfillElement);
    }
    play() {
        __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").play();
    }
    get isPlaying() {
        return __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").isPlaying;
    }
    get volume() {
        return __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").volume;
    }
    set volume(value) {
        __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").volume = value;
    }
    get fullscreenEnabled() {
        return __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").fullscreenEnabled;
    }
    get isFullscreen() {
        return __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").isFullscreen;
    }
    setFullscreen(isFull) {
        __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").setFullscreen(isFull);
    }
    enterFullscreen() {
        __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").enterFullscreen();
    }
    exitFullscreen() {
        __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").exitFullscreen();
    }
    async downloadSwf() {
        await __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").downloadSwf();
    }
    pause() {
        __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").pause();
    }
    set traceObserver(observer) {
        __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").traceObserver = observer;
    }
    debugPlayerInfo() {
        return "";
    }
    PercentLoaded() {
        // [NA] This is a stub - we need to research how this is actually implemented (is it just base swf loadedBytes?)
        if (this.readyState === ReadyState.Loaded) {
            return 100;
        }
        else {
            return 0;
        }
    }
    get config() {
        return __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").config;
    }
    set config(value) {
        __classPrivateFieldGet(this, _RufflePlayerElement_inner, "f").config = value;
    }
}
_RufflePlayerElement_inner = new WeakMap();
/**
 * Copies attributes and children from another element to a target element.
 * Used by the polyfill elements, RuffleObject and RuffleEmbed.
 *
 * @param element The element to copy all attributes from.
 * @param destination The element to copy all attributes to.
 */
export function copyElement(element, destination) {
    if (element) {
        for (const attribute of element.attributes) {
            if (attribute.specified) {
                // Issue 468: Chrome "Click to Active Flash" box stomps on title attribute
                if (attribute.name === "title" &&
                    attribute.value === "Adobe Flash Player") {
                    continue;
                }
                try {
                    destination.setAttribute(attribute.name, attribute.value);
                }
                catch (err) {
                    // The embed may have invalid attributes, so handle these gracefully.
                    console.warn(`Unable to set attribute ${attribute.name} on Ruffle instance`);
                }
            }
        }
        for (const node of Array.from(element.children)) {
            destination.appendChild(node);
        }
    }
}
